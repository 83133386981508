@import 'npm:the-new-css-reset/css/reset.css';
@import 'colors';
@import 'fonts';



html {
  --scrollbar-opacity: .8;
}

::-webkit-scrollbar {
  width: .7rem;

  &-track {
    background: rgba($color-black, calc(var(--scrollbar-opacity)));
  }

  &-thumb {
    background: rgba($color-white, calc(var(--scrollbar-opacity) * .45));
    border-radius: 1rem;

    &:hover {
      background: rgba($color-white, calc(var(--scrollbar-opacity)));
    }
  }
}

// If smoothScroll enabled, replace value by following :
// #viewport {
//   overflow: hidden;
//   position: fixed;
//   height: 100%;
//   width: 100%;
//   inset: 0;

//   #scroll-container {
//     position: absolute;
//     overflow: hidden;
//     width: 100%;
//   }
// }

body {
  &.rayClickable {
    cursor: pointer;
  }
}

#viewport {
  overscroll-behavior-y: none;

  canvas.webglRenderer,
  .cssRendererWrapper,
  header {
    position: fixed;
    inset: 0;
  }

  header {
    z-index: 3;
    pointer-events: none;

    img#logo {
      width: 5vw;
      padding: 1vw;
      pointer-events: all;
    }
  }

  #scroll-container {
    position: relative;
    z-index: 2;

    main {
      section {
        &:not(:last-child) {
          height: 100vh;
        }

        &:last-child {
          height: 40vh;
        }

        color: #ffeded;
        position: relative;
        z-index: 2;
        top: 0;
        display: grid;
        place-content: center;

        // &#home {
        //   h1 {
        //     opacity: 1;
        //     text-align: center;
        //     font-size: 6.8vw;
        //     color: $color-yellow;
        //     line-height: 1.8;

        //     span {
        //       display: block;

        //       &:first-of-type {
        //         font-size: 2.31em;
        //         line-height: 0.5;
        //       }

        //       &:last-of-type {
        //         font-size: 1.83em;
        //         line-height: 1.1;
        //       }
        //     }
        //   }
        // }
      }
    }
  }

  .cssRendererWrapper {
    z-index: 10;
    pointer-events: none !important;

    .presentation-text {
      backface-visibility: hidden;
      font-size: 2.1vh;

      &-1,
      &-2 {
        >p:not(last-of-type) {
          margin-bottom: 1rem;
        }
      }

      &-1 {
        width: 64vh;
        height: 33vh;
        columns: 2;
        color: $color-black;
      }

      &-2 {
        width: 30vh;
      }

      &-3 {
        text-align: center;
        white-space: nowrap;
      }
    }

    .callToScroll {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: .5rem;
      color: $color-orange;

      &:hover {
        filter: brightness(1.5) contrast(1.5);
      }
    }
  }
}
