:where(:not(html, iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

@font-face {
  font-family: MarvinVisionsBold;
  src: url("MarvinVisionsBold.08e776c8.woff2") format("woff2"), url("MarvinVisionsBold.2041ac76.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: MarvinVisionsBold, sans-serif;
  font-size: max(16px, 1.2vw);
}

html {
  --scrollbar-opacity: .8;
}

::-webkit-scrollbar {
  width: .7rem;
}

::-webkit-scrollbar-track {
  background: rgba(30, 30, 30, calc(var(--scrollbar-opacity) ) );
}

::-webkit-scrollbar-thumb {
  background: rgba(248, 248, 248, calc(var(--scrollbar-opacity)  * .45) );
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(248, 248, 248, calc(var(--scrollbar-opacity) ) );
}

body.rayClickable {
  cursor: pointer;
}

#viewport {
  overscroll-behavior-y: none;
}

#viewport canvas.webglRenderer, #viewport .cssRendererWrapper, #viewport header {
  position: fixed;
  inset: 0;
}

#viewport header {
  z-index: 3;
  pointer-events: none;
}

#viewport header img#logo {
  width: 5vw;
  pointer-events: all;
  padding: 1vw;
}

#viewport #scroll-container {
  z-index: 2;
  position: relative;
}

#viewport #scroll-container main section {
  color: #ffeded;
  z-index: 2;
  place-content: center;
  display: grid;
  position: relative;
  top: 0;
}

#viewport #scroll-container main section:not(:last-child) {
  height: 100vh;
}

#viewport #scroll-container main section:last-child {
  height: 40vh;
}

#viewport .cssRendererWrapper {
  z-index: 10;
  pointer-events: none !important;
}

#viewport .cssRendererWrapper .presentation-text {
  backface-visibility: hidden;
  font-size: 2.1vh;
}

#viewport .cssRendererWrapper .presentation-text-1 > p:not(last-of-type), #viewport .cssRendererWrapper .presentation-text-2 > p:not(last-of-type) {
  margin-bottom: 1rem;
}

#viewport .cssRendererWrapper .presentation-text-1 {
  width: 64vh;
  height: 33vh;
  columns: 2;
  color: #1e1e1e;
}

#viewport .cssRendererWrapper .presentation-text-2 {
  width: 30vh;
}

#viewport .cssRendererWrapper .presentation-text-3 {
  text-align: center;
  white-space: nowrap;
}

#viewport .cssRendererWrapper .callToScroll {
  color: #e65f1d;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  display: flex;
}

#viewport .cssRendererWrapper .callToScroll:hover {
  filter: brightness(1.5) contrast(1.5);
}

/*# sourceMappingURL=index.de810efa.css.map */
