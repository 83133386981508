@mixin font-face($font-family, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url('../assets/fonts/#{$font-family}.woff2') format('woff2'),
    url('../assets/fonts/#{$font-family}.woff') format('woff');
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

@include font-face('MarvinVisionsBold', bold, normal);

body {
  font-family: 'MarvinVisionsBold', sans-serif;
  font-size: max(16px, 1.2vw);
}
